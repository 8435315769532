class ImageGallery {
    constructor(galleryElement) {
        this.gallery = galleryElement;
        this.images = this.gallery.querySelectorAll('img.gallery-image');
        this.currentIndex = 0;
        this.init();
        this.createModal();
    }

    init() {
        this.setupEventListeners();
    }

    setupEventListeners() {
        this.images.forEach((img, index) => {
            img.addEventListener('click', () => this.openImage(index));
        });
        window.addEventListener('keyup', this.handleKeyUp.bind(this));
    }

    createModal() {
        // Create modal container
        this.modal = document.createElement('div');
        this.modal.classList.add('fixed', 'inset-0', 'bg-blue-gray-900/70', 'flex', 'justify-center', 'items-center', 'hidden', 'z-50');
        document.body.appendChild(this.modal);

        // Create modal content (image)
        this.modalImage = document.createElement('img');
        this.modalImage.classList.add('max-w-[80%]', 'max-h-[80vh]');
        this.modal.appendChild(this.modalImage);

        // Create close button
        this.closeButton = document.createElement('span');
        this.closeButton.innerHTML = '&times;';
        this.closeButton.classList.add('absolute', 'top-5', 'right-10', 'text-white', 'text-4xl', 'cursor-pointer');
        this.modal.appendChild(this.closeButton);

        // Event listener for closing the modal
        this.closeButton.addEventListener('click', () => {
            this.modal.classList.add('hidden');
        });
        this.modal.addEventListener('click', () => {
            this.modal.classList.add('hidden');
        });
    }

    openImage(index) {
        this.currentIndex = index;
        const imageUrl = this.images[index].dataset.largeUrl;
        this.modal.classList.remove('hidden');
        this.modalImage.src = imageUrl;
    }

    handleKeyUp(event) {
        if (event.key === 'ArrowRight') {
            this.nextImage();
        } else if (event.key === 'ArrowLeft') {
            this.prevImage();
        } else if (event.key === 'Escape') {
            this.modal.classList.add('hidden');
        }
    }

    nextImage() {
        if (this.currentIndex < this.images.length - 1) {
            this.openImage(this.currentIndex + 1);
        } else {
            this.openImage(0);
        }
    }

    prevImage() {
        if (this.currentIndex > 0) {
            this.openImage(this.currentIndex - 1);
        } else {
            this.openImage(this.images.length - 1);
        }
    }
}

document.addEventListener('turbo:frame-load', () => {
    document.querySelectorAll('.feed-item-gallery').forEach(gallery => {
        new ImageGallery(gallery);
    });
});
