// Function to check if ads are enabled via meta tag
function adsEnabled() {
    const metaTag = document.querySelector('meta[name="ads:enabled"]');
    return metaTag && metaTag.content === 'true';
}

// Function to adjust the margin-bottom of all .drawer-content elements
function adjustDrawerContentMargin() {
    const mobileWrapper = document.getElementById('adhesion_mobile_wrapper');
    const drawerContents = document.querySelectorAll('.drawer-content');

    if (mobileWrapper) {
        // Function to apply margin-bottom
        const applyMargin = () => {
            const marginBottomValue = `${mobileWrapper.offsetHeight}px`;
            drawerContents.forEach(element => {
                element.style.marginBottom = marginBottomValue;
            });
        };

        // Immediately attempt to apply margin
        applyMargin();

        // Retry after a delay to account for dynamic content loading
        setTimeout(applyMargin, 500); // Adjust delay as needed
    }
}

// Function to observe changes within the adhesion_mobile_wrapper
function observeWrapperChildChanges() {
    const mobileWrapper = document.getElementById('adhesion_mobile_wrapper');
    if (mobileWrapper) {
        const childObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    adjustDrawerContentMargin();
                }
            });
        });

        childObserver.observe(mobileWrapper, { childList: true, subtree: true });
    }
}

// Main observer to detect when adhesion_mobile_wrapper is added to the DOM
const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
            mutation.addedNodes.forEach((node) => {
                if (node.id === 'adhesion_mobile_wrapper') {
                    adjustDrawerContentMargin();
                    observeWrapperChildChanges(); // Start observing changes within the wrapper
                               }
            });
        }
    });
});

document.addEventListener("turbo:load", function() {
    const drawerContents = document.querySelector('.drawer-content');

    if(drawerContents) {
        // Start observing the document body for child additions
        observer.observe(document.body, {childList: true, subtree: true});

        // Also run the function on initial load in case the element is already in the DOM
        adjustDrawerContentMargin();
    }
});