document.addEventListener('turbo:load', () => {
    const chartElement = document.getElementById('skin-rating-chart');
    if (chartElement) {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/chart.js';
        script.onload = () => initChart(chartElement);
        document.head.appendChild(script);
    }
});

function initChart(chartElement) {
    const data = JSON.parse(chartElement.dataset.ratings);

    const colors = {
        0: 'rgb(252, 165, 165)', // red-300 (bad)
        1: 'rgb(253, 224, 71)',  // yellow-300 (meh)
        2: 'rgb(252, 211, 77)',  // amber-300 (okay)
        3: 'rgb(190, 242, 100)', // lime-300 (good)
        4: 'rgb(110, 231, 183)'  // emerald-300 (great)
    };

    const getColor = (ctx) => {
        const value = ctx.raw;
        return colors[Math.floor(value)] || 'rgb(107, 114, 128)'; // gray-500 as default
    };


    new Chart(chartElement, {
        type: 'line',
        data: {
            labels: data.map(d => d[0]),
            datasets: [{
                data: data.map(d => d[1]),
                borderColor: 'rgb(229, 231, 235)', // warm-gray-200
                tension: 0.4,
                spanGaps: true,
                pointBackgroundColor: getColor,
                pointRadius: 6,
                pointBorderWidth: 0, // Remove stroke from points
                backgroundColor: 'white',
                fill: true,
            }]
        },
        options: {
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    beginAtZero: true,
                    max: 4.5,
                    display: false
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            },
            elements: {
                line: {
                    fill: true
                }
            }
        }
    });
}
