import TomSelect from 'tom-select';

document.addEventListener("turbo:load", function() {
    let frequencySelects = document.querySelectorAll('.frequency-select')

    frequencySelects.forEach(function(frequencySelect) {
      if(frequencySelect.tagName == "SELECT") {
          new TomSelect(`#${frequencySelect.id}`,{
              closeAfterSelect: true,
              plugins: ['input_autogrow'],
              onChange: function(value){
                  document.activeElement.blur();
              }
          });
      }
    });
});
