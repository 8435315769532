document.addEventListener("turbo:load", function() {
  if(document.getElementById("filters-toggle")) {
    let filtersOpen = false;
    let browserWidth = window.innerWidth;

    // We only want to register this listener on desktop
    if (browserWidth > 1024) {
      document.getElementById("filters-toggle").addEventListener("click", function () {
        if (filtersOpen) {
          closeNav();
          filtersOpen = false;
        } else {
          openNav();
          filtersOpen = true;
        }
      });
    }
  }
});

function openNav() {
  document.getElementById("filters").style.width = "425px";
  document.getElementById("site-body").style.marginLeft = "425px";
}

function closeNav() {
  document.getElementById("filters").style.width = "0";
  document.getElementById("site-body").style.marginLeft = "0";
}