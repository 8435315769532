document.addEventListener("turbo:load", function() {
    let analysisDetailsFrame = document.getElementById('analysis_details');
    if(analysisDetailsFrame) {
        addTurboFrameRenderTransition();
    }

    let productsTable = document.getElementById('products-index-content');
    if(productsTable) {
        addTurboFrameRenderTransition();
    }

    let feedItems = document.getElementById('feed_items');
    if(feedItems) {
        addTurboFrameRenderTransition();
    }
});

function addTurboFrameRenderTransition() {
    // addEventListener("turbo:before-frame-render", (event) => {
    //     if (document.startViewTransition) {
    //         const originalRender = event.detail.render
    //         event.detail.render = (currentElement, newElement) => {
    //             document.startViewTransition(()=> originalRender(currentElement, newElement))
    //         }
    //     }
    // });

}