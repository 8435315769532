document.addEventListener("turbo:load", function() {
  registerInsightsToggleListeners();
});

function registerInsightsToggleListeners() {
  let insightsToggleElements = document.getElementsByClassName("insights-toggle");

  if (insightsToggleElements.length > 0) {
    let filtersOpen = false;
    let browserWidth = window.innerWidth;

    // We only want to register this listener on desktop
    if (browserWidth > 1024) {
      for (const insightsToggleElement of insightsToggleElements) {
        insightsToggleElement.addEventListener("click", function () {
          if (filtersOpen) {
            closeNav();
            filtersOpen = false;
          } else {
            openNav();
            filtersOpen = true;
          }
        });
      }
    }
  }
}

function openNav() {
  document.getElementById("insights").style.width = "625px";
  document.getElementById("site-body").style.marginRight = "625px";
}

function closeNav() {
  document.getElementById("insights").style.width = "0";
  document.getElementById("site-body").style.marginRight = "0";
}