import { BridgeComponent } from "@hotwired/strada"

export default class extends BridgeComponent {
    static component = "share-button"

    connect() {
        super.connect()
        this.#notifyBridgeOfConnect()
    }

    #notifyBridgeOfConnect() {
        const element = this.bridgeElement
        const title = element.title
        const image = element.bridgeAttribute("ios-image")
        const side = element.bridgeAttribute("side") || "right"
        const url = element.bridgeAttribute("url")

        this.send("connect", { title, image, side, url })
    }
}