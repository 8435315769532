function isNativeApp() {
  return document.documentElement.hasAttribute('data-turbo-native-app');
}

function setupNativeHaptic() {
  if (!isNativeApp()) return;

  document.querySelectorAll('.trigger-native-haptic').forEach(element => {
    element.addEventListener('click', () => {
      window.webkit.messageHandlers.nativeApp.postMessage({name: "trigger_haptic"});
    });
  });
}

document.addEventListener("turbo:load", setupNativeHaptic);
document.addEventListener('turbo:frame-load', setupNativeHaptic);
