
document.addEventListener("turbo:load", function() {
    let variantIcons = document.querySelectorAll('.variant-icon');

    variantIcons.forEach((icon) => {
        icon.addEventListener('mouseover', handleVariantIconHover);
    });
});

function handleVariantIconHover(event) {
    // Get the value of the "data-variant-name" attribute
    let variantName = event.currentTarget.getAttribute('data-variant-name');

    // Find the element with the ID "display-variant-name"
    let displayVariantName = document.getElementById('display-variant-name');

    // Update the content of the element with the variant name
    displayVariantName.textContent = variantName;
}