import {debounce} from "../application";

document.addEventListener("turbo:load", function() {
    let routineProductNoteInputs = document.querySelectorAll('.routine-product-note-input');

    if(routineProductNoteInputs) {
        routineProductNoteInputs.forEach(function(routineProductNoteInput) {
            routineProductNoteInput.addEventListener("input", debounce(function () {
                routineProductNoteInput.form.requestSubmit();
            }, 1000));
        });
    }
});
