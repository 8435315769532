import Cropper from 'cropperjs';
import {
  Spinner
} from 'spin.js';

import {opts} from '../src/spinner_opts';

document.addEventListener("turbo:load", function() {
  const imageInputField = document.getElementById('ingredientUpload')
  const textAnalysisButton = document.getElementById('text-analyse-button')

  if(imageInputField) {
    imageInputField.addEventListener("change", function() {
      const image = document.getElementById("ing-image");
      let cropBoxData;
      let canvasData;
      let cropper;

      if (!this.files || !this.files[0]) {
        return; // Handle the case where there are no files or the first file is missing
      }

      const file = this.files[0]

      if (file.type.match(/^image\//)) {
        const reader = new FileReader();

        reader.onloadend = () => {
          image.src = reader.result;

          cropper = new Cropper(image, {
            autoCropArea: 0.5,
            ready: () => {
              cropper.setCropBoxData(cropBoxData).setCanvasData(canvasData);

              document.getElementById('analyze-btn').addEventListener("click", () => {
                submitCroppedImage(cropper, imageInputField);
              });
            }
          });
        };

        reader.readAsDataURL(file);
      }
    });
  }

  if(textAnalysisButton) {
    textAnalysisButton.addEventListener("click", function () {
      new Spinner(opts).spin(document.getElementById('analyser-form'));
    });
  }
});

function submitCroppedImage(cropper, imageInputField) {
  cropper.getCroppedCanvas().toBlob(function(blob) {
    let mimeType = blob.type;
    let fileName = 'cropped-image.' + mimeType.split('/')[1];
    let croppedFile = new File([blob], fileName, { type: mimeType });  // Create a File object from the blob
    let dataTransfer = new DataTransfer();

    dataTransfer.items.add(croppedFile);

    imageInputField.files = dataTransfer.files; // Set the value of the file input with the DataTransfer object

    imageInputField.form.requestSubmit();

    new Spinner(opts).spin(document.getElementById('crop-modal-content'));
    document.getElementById('crop-modal-content').classList.add("brightness-50");
  });
}