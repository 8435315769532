import {
  Spinner
} from 'spin.js';

import {opts} from '../src/spinner_opts';
import TomSelect from "tom-select";

document.addEventListener("turbo:load", function() {
  if (document.getElementById("brands-filter")) {
    const inputs = document.getElementsByTagName("input");
    for(let i = 0; i < inputs.length; i++) {
      if(!inputs[i].classList.contains("filter-input")) { continue; }

      if (inputs[i].type == "checkbox") {
        inputs[i].addEventListener("change", function (event) {
          if (event.target.classList.contains("country-input")) {
            let filter_inputs = document.getElementsByClassName("country-input");
            for (let j = 0; j < filter_inputs.length; j++) {
              if (filter_inputs[j] != event.target) {
                filter_inputs[j].checked = false
              }
            }
          }

          submitForm();
        });
      }

    }

    const country_select_bar = new TomSelect("#filter-country-select",{
      sortField: 'text',
      maxItems: 1,
      plugins: ['remove_button'],
      // custom rendering functions for options and items
      render: {
        option: function(item, escape) {
          return `<div class="py-3 flex items-center">
                <div class='flex flex-wrap py-0.5'>
                    <div class="text-blue-gray-700">
                      ${ escape(item.text) }
                    </div>
                </div>
            </div>`;
        }
      }
    });

    country_select_bar.on('item_add', function() {
      let country_inputs = document.getElementsByClassName("country-input")
      for(let i = 0; i < country_inputs.length; i++) {
        country_inputs[i].checked = false;
      }
    });

    let country_inputs = document.getElementsByClassName("country-input")
    for(let i = 0; i < country_inputs.length; i++) {
      country_inputs[i].addEventListener("change", function() {
        country_select_bar.clear(true);
      });
    }

    document.getElementById("filter-country-select").addEventListener("change", function() {
      let country_inputs = document.getElementsByClassName("country-input");
      for (let j = 0; j < country_inputs.length; j++) {
        country_inputs[j].checked = false
      }

      submitForm();
    });
  }
});


function submitForm() {
  let form = document.getElementById("brands-filter")
  new Spinner(opts).spin(document.getElementById('brands-table'));
  document.getElementById("brands-table").classList.add("opacity-50");

  form.requestSubmit();
}
