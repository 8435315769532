document.addEventListener('turbo:load', function() {
    if(document.getElementById('community-feed-items')) {
        const baseFeedItemsPath = document.getElementById('community-feed-items').dataset.basePath;
        const recommendedSrc = `${baseFeedItemsPath}&include_week_in_review=true&filter=recommended`;
        const followingSrc = `${baseFeedItemsPath}&filter=following`;

        const recommendedButton = document.querySelector("button[data-option='recommended']");
        const followingButton = document.querySelector("button[data-option='following']");
        const indicator = document.querySelector(".indicator");
        const feedItemContainer = document.querySelector(".feed-item-container");
        const feedItemsRecommended = document.getElementById("feed_items_recommended");
        const feedItemsFollowing = document.getElementById("feed_items_following");

        function updateSrcIfNeeded(option) {
            if (option === 'recommended' && !feedItemsRecommended.getAttribute('src')) {
                feedItemsRecommended.setAttribute('src', recommendedSrc);
            } else if (option === 'following' && !feedItemsFollowing.getAttribute('src')) {
                feedItemsFollowing.setAttribute('src', followingSrc);
            }
        }

        function toggleClasses(option) {
            const isRecommended = option === 'recommended';
            recommendedButton.classList.toggle("text-warm-gray-800", isRecommended);
            followingButton.classList.toggle("text-warm-gray-800", !isRecommended);
            indicator.classList.toggle("translate-x-full", !isRecommended);
            indicator.classList.toggle("translate-x-0", isRecommended);
            feedItemContainer.classList.toggle("-translate-x-full", !isRecommended);
            feedItemContainer.classList.toggle("translate-x-0", isRecommended);
        }

        function setActiveTab(option) {
            toggleClasses(option);
            updateSrcIfNeeded(option);
            sessionStorage.setItem('selectedTab', option); // Save the selected tab to session storage
        }

        recommendedButton.addEventListener('click', () => setActiveTab('recommended'));
        followingButton.addEventListener('click', () => setActiveTab('following'));

        // Check session storage for the selected tab and set it as active
        const savedTab = sessionStorage.getItem('selectedTab') || 'recommended';
        setActiveTab(savedTab);
    }
});