document.addEventListener("turbo:load", function() {
  initializeCopyButtons();
});

document.addEventListener("turbo:frame-load", function() {
  initializeCopyButtons();
});

function initializeCopyButtons() {
  if(document.getElementById("copyBtn")) {
    document.getElementById("copyBtn").addEventListener("click", function() {
      /* Get the text field */
      let copyText = document.getElementById("copyInput");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      // Change tooltip to inform user that copy was successful
      document.getElementById("copyBtn").innerHTML = 'Copied!';
    });
  }
  if(document.getElementById("copyShare")) {
    document.getElementById("copyShare").addEventListener("click", function () {
      /* Get the text field */
      let copyInput = document.getElementById("copyInput")

      copyInput.type = "text";
      copyInput.select();

      /* Copy the text inside the text field */
      document.execCommand("copy");

      copyInput.type = "hidden";

      // Change tooltip to inform user that copy was successful
      document.getElementById("copied").innerHTML = 'Copied!';
    });
  }
}
