document.addEventListener("turbo:load", function () {
  photoUploadPreview();
  setupPhotoManagement();
});

document.addEventListener("turbo:frame-render", function () {
  photoUploadPreview();
  setupPhotoManagement();
});

function photoUploadPreview() {
  let photoUploadInputs = document.querySelectorAll(
    ".preview-image-file-upload",
  );

  photoUploadInputs.forEach((photoUploadInput) => {
    photoUploadInput.addEventListener("change", function (event) {
      let current_photos = document.getElementById("current-review-photos");

      if (current_photos) {
        current_photos.classList.add("hidden");
      }

      let input = event.target;
      let files = input.files;

      let previewContainer = document.getElementById("preview_container");
      let images = previewContainer.querySelectorAll("img");
      images.forEach((img) => img.remove()); // Clear previous image previews

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        let previewImg = document.createElement("img");
        previewContainer.prepend(previewImg);

        previewImg.src = URL.createObjectURL(file);
        previewImg.onload = function () {
          URL.revokeObjectURL(previewImg.src); // free memory
        };
      }
    });
  });
}


function setupPhotoManagement() {
  const photoManagement = {
    photos: [],
    fileInput: document.getElementById('photo-upload'),
    previewContainer: document.getElementById('photo-preview'),
    deletedPhotoIds: [],

    init() {
      if (this.fileInput) {
        this.fileInput.addEventListener('change', (event) => this.handleFileUpload(event));
      }
      this.setupDeleteButtons();
    },

    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      this.photos = [...this.photos, ...newFiles];
      this.updateFileInput();
      this.renderPhotos();
    },

    removeNewPhoto(index) {
      this.photos.splice(index, 1);
      this.updateFileInput();
      this.renderPhotos();
    },

    updateFileInput() {
      // Create a new FileList object
      const dataTransfer = new DataTransfer();
      this.photos.forEach(file => dataTransfer.items.add(file));
      this.fileInput.files = dataTransfer.files;
    },

    setupDeleteButtons() {
      document.querySelectorAll('.delete-photo').forEach(button => {
        button.addEventListener('click', () => this.deleteExistingPhoto(button.dataset.photoId));
      });
    },

    deleteExistingPhoto(photoId) {
      const photoElement = document.getElementById(`photo-${photoId}`);
      if (photoElement) {
        photoElement.remove();
        this.deletedPhotoIds.push(photoId);
        this.updateDeletedPhotoIds();
      }
    },

    renderPhotos() {
      // Remove existing new photo previews
      this.previewContainer.querySelectorAll('.new-photo-preview').forEach(el => el.remove());

      this.photos.forEach((file, index) => {
        const photoElement = document.createElement('div');
        photoElement.className = 'relative new-photo-preview h-full shadow-md rounded-lg';
        const reader = new FileReader();
        reader.onload = (e) => {
          photoElement.innerHTML = `
            <img src="${e.target.result}" alt="Diary entry photo" class="w-full h-32 object-cover rounded-lg">
            <button type="button" class="delete-photo trigger-native-haptic absolute top-2 right-2 aspect-square size-5 flex items-center justify-center bg-orange-300 text-orange-900 rounded-full p-1">
              -
            </button>
          `;
          photoElement.querySelector('.delete-photo').addEventListener('click', () => this.removeNewPhoto(index));
          this.previewContainer.appendChild(photoElement);
        };
        reader.readAsDataURL(file);
      });
    },

    updateDeletedPhotoIds() {
      const hiddenField = document.getElementById('deleted_photo_ids');
      if (hiddenField) {
        hiddenField.value = this.deletedPhotoIds.join(',');
      }
    }
  };

  photoManagement.init();
}