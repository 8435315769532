import {debounce} from "../application";

document.addEventListener("turbo:load", function() {
   let textInput = document.getElementById("product-submission-name");

   if(textInput) {
      textInput.addEventListener("input", debounce(function() {
         let brandId = document.getElementById("brand-select-bar").value;
         let productName = textInput.value;
         let resultElement = document.getElementById("potential-duplicates");

         fetchPotentialDuplicates(productName, brandId).then(data => {
            // Get top 7 results with over 0.4 similarity
            let filteredDuplicates = data.filter(obj => {
                let similarity = cosineSimilarity(productName, obj.text);
                return similarity > 0.40;
            }).slice(0, 5);

            if (filteredDuplicates.length === 0) {
               return "No matching objects found.";
            }

            let linksHtml = "<p class='text-sm text-indigo-800 font-semibold mt-2 leading-tight'>Just checking - are any of these existing products what you're looking for?</p><ul class='list-disc list-inside ml-3 mt-2'>" + filteredDuplicates.map(obj => {
               let linkText = `${obj.heading} ${obj.text}`;
               return `<li><a class="text-blue-gray-800 font-semibol text-sm font-header hover:underline" href="${obj.url}">
                        ${linkText}
                        <svg class="inline-flex w-4 h-4 text-blue-gray-500" fill="none" stroke="currentColor" stroke-width="1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
                        </svg>
                        </a></li>`;
            }).join("") + "</ul>";

            resultElement.innerHTML = linksHtml;
         })
      }, 200));
   }
})

function fetchPotentialDuplicates(productName, brandId) {
   let search_url = `/search/products_search.json?search[term]=${encodeURIComponent(productName)}&search[brand_id]=${brandId}`

   return fetch(search_url)
    .then(response => response.json())
    .then(data => {
       return data;
    })
    .catch((e) => {
       console.log(e);
    });
}

function cosineSimilarity(str1, str2) {
    const text1 = str1.toLowerCase().split('');
    const text2 = str2.toLowerCase().split('');
    const intersection = text1.filter(letter => text2.includes(letter)).length;
    return intersection / Math.sqrt(text1.length * text2.length);
}