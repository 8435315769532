window.onpopstate = function(event) {

    const stateKeys = [
        'search', 
        'searchDupes', 
        'addProduct', 
        'routineFilterState', 
        'brandFilterState', 
        'productFilterState'
    ];

    // Check if any of the defined state keys exist in the event.state
    let shouldReload = stateKeys.some(key => event.state && event.state[key]);

    // Additional check for "turbo" state key if an element with id "products-table" is on the page
    // No idea why we need this. For some reason, the first pushed state from our products index view is a 'turbo'
    // event rather than productFilterState. This workaround ensures we reload the page on back navigation
    if (event.state && event.state.turbo && document.getElementById('products-table')) {
        shouldReload = true;
    }

    if (shouldReload) {
        reloadPage();
    }
};

function reloadPage() {
    location.reload();
}
