document.addEventListener("turbo:load", function() {
    setupTimeAgoInWords();
});

document.addEventListener("turbo:frame-load", function() {
    setupTimeAgoInWords();
});

function setupTimeAgoInWords() {
    document.querySelectorAll('.time-ago-in-words').forEach(element => {
        const date = new Date(element.dataset.time.trim());
        const seconds = Math.floor((new Date() - date) / 1000);

        const intervals = [
            { seconds: 31536000, unit: 'year' },
            { seconds: 2592000, unit: 'month' },
            { seconds: 86400, unit: 'day' },
            { seconds: 3600, unit: 'hour' },
            { seconds: 60, unit: 'minute' }
        ];

        const interval = intervals.find(i => seconds >= i.seconds) || { seconds: 1, unit: 'second' };
        const count = Math.floor(seconds / interval.seconds);
        const unit = count === 1 ? interval.unit : `${interval.unit}s`;

        element.textContent = count === 0 ? 'just now' : `${count} ${unit} ago`;
    });
}
