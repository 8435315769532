document.addEventListener("turbo:load", function() {
  if(document.getElementById("comment-field")) {
  document.getElementById("comment-field").addEventListener("keyup", function(e) {
    if (e.target.value.length > 2000) {
      return false;
    }
    document.getElementById("remaining").innerHTML = (e.target.value.length) + '/2000';
  });
  }

  let note_areas = document.getElementsByClassName("note-area");
  for(let i = 0; i< note_areas.length; i++) {
    note_areas[i].addEventListener("keyup", function(event) {
      if (event.target.value.trim().length > 240) {
        return false;
      }

      event.target.parentElement.querySelector('.note-remaining').textContent = (240 - event.target.value.trim().length).toString();
    });
  }

});
