// This code is a workaround so that we can auto-focus the mobile search modal input on Safari
// We create a temp input that is focused when they click the button to open the modal, and then remove that input and focus the correct one
document.addEventListener("turbo:load", function() {
    initializeFocusHelperAreas('.mobile-search-area', '.mobile-modal-search-button', '.mobile-modal-search-input');
    initializeFocusHelperAreas('.social-footer-area', '.feed-item-comment-button', '.feed-item-comment-input');
});

document.addEventListener("turbo:frame-load", function() {
    initializeFocusHelperAreas('.mobile-search-area', '.mobile-modal-search-button', '.mobile-modal-search-input');
    initializeFocusHelperAreas('.social-footer-area', '.feed-item-comment-button', '.feed-item-comment-input');
});

function initializeFocusHelperAreas(areaSelector, buttonSelector, inputSelector) {
    const areas = Array.from(document.querySelectorAll(areaSelector));
    areas.forEach(area => {
        let buttonElements = Array.from(area.querySelectorAll(buttonSelector));

        buttonElements.forEach(buttonElement => {
            buttonElement.addEventListener("click", function() {
                inputFocusHelper(area, inputSelector);
            });

            buttonElement.addEventListener("touchstart", function() {
                initiateKeyboard(area, inputSelector);
            }, { passive: true });
        })
    });
}

function inputFocusHelper(areaElement, inputSelector) {
    cleanupTempInputs();

    const inputHelper = document.createElement('input');
    inputHelper.setAttribute("class", "temp-input size-0 ring-0 border-0 outline-0 focus:ring-0 focus:outline-0 focus:border-0");

    if (areaElement) {
        areaElement.appendChild(inputHelper);
        inputHelper.focus();

        // Triggering touchstart to initiate keyboard for mobile devices
        const touchStartEvent = new Event('touchstart', { bubbles: true });
        const button = areaElement.querySelector(inputSelector);

        if (button) {
            button.dispatchEvent(touchStartEvent);
        }
    }
}

function initiateKeyboard(areaElement, inputSelector) {
    setTimeout(() => {
        const input = areaElement.querySelector(inputSelector);
        if (input) {
            input.focus();
            cleanupTempInputs();
        }
    }, 180);
}

// Remove temp inputs from the DOM
function cleanupTempInputs() {
    const tempInputs = Array.from(document.getElementsByClassName('temp-input'));
    tempInputs.forEach(element => {
        element.remove();
    });
}