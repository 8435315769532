import { BridgeComponent } from "@hotwired/strada";

export default class extends BridgeComponent {
  static component = "button";

  connect() {
    super.connect();
    this.#notifyBridgeOfConnect();
  }

  #notifyBridgeOfConnect() {
    const element = this.bridgeElement;
    const image = element.bridgeAttribute("ios-image");
    const side = element.bridgeAttribute("side") || "right";
    const activeTitle = element.bridgeAttribute("active-title");

    this.send(
      "connect",
      { title: element.title, activeTitle, image, side },
      () => {
        this.element.click();
      },
    );
  }
}
