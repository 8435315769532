import Cookies from "js-cookie";
import {debounce} from "../application";

document.addEventListener("turbo:load", function() {
    initProductIndexAds();
    initComparisonIndexAds();
    initIngredientsExplainedAds();
});

document.addEventListener("turbo:frame-load", function() {
    initIngredientsExplainedAds();
});

document.addEventListener("turbo:after-stream-render", debounce(function() {
    initProductIndexAds();
}, 250));

function insertHint(element, className) {
    let hint = document.createElement("div");
    hint.classList.add(className);
    element.insertAdjacentElement("afterend", hint);
}

function initComparisonIndexAds() {
    let comparisonsTable = document.getElementById('comparisons-list');

    if(comparisonsTable) {
        Array.from(comparisonsTable.children).forEach(function(comparisonElement, index) {
            insertHint(comparisonElement, "content_mobile_hint");

            if ((index - 1) % 2 == 0) {
                insertHint(comparisonElement, "content_desktop_hint");
            }
        });
    }
}

function initIngredientsExplainedAds() {
    let ingredientsExplainedList = document.getElementById('ingredients-explained-list');

    if(ingredientsExplainedList) {
        // Early exit if we've already inserted the hints
        if (ingredientsExplainedList.querySelector('.content_mobile_hint, .content_desktop_hint')) {
            return;
        }

        Array.from(ingredientsExplainedList.querySelectorAll(".ingredient-table-row")).forEach(function(ingredientRow, index) {
            if ((index - 1) % 2 == 0) {
                insertHint(ingredientRow, "content_mobile_hint");
            }

            if ((index - 1) % 3 == 0) {
                insertHint(ingredientRow, "content_desktop_hint");
            }
        });
    }
}

function initProductIndexAds() {
    let productsTable = document.getElementById('products-table');

    if(productsTable) {
        let lastMobileAd = null;
        let lastDesktopAd = null;

        // Manually find the last of each type
        Array.from(productsTable.children).forEach(child => {
            if (child.classList.contains('content_mobile_hint')) {
                lastMobileAd = child;
            } else if (child.classList.contains('content_desktop_hint')) {
                lastDesktopAd = child;
            }
        });

        // Determine which of the two is actually the last in the DOM
        let lastAd = null;
        if (lastMobileAd && lastDesktopAd) {
            lastAd = lastMobileAd.compareDocumentPosition(lastDesktopAd) & Node.DOCUMENT_POSITION_FOLLOWING ? lastDesktopAd : lastMobileAd;
        } else {
            lastAd = lastMobileAd || lastDesktopAd;
        }

        let startIndex = 0;

        // If a last ad element exists, calculate the new starting index
        if(lastAd) {
            let allChildren = Array.from(productsTable.children);
            startIndex = allChildren.indexOf(lastAd) + 1;
        }

        // Iterate from the startIndex, adding ads to new content
        Array.from(productsTable.children).slice(startIndex).forEach(function(productElement, index) {
            // Adjusted index to account for the new starting point
            let adjustedIndex = index + startIndex;

            if (Cookies.get('visits') == 1) {
                insertHint(productElement, "content_mobile_hint");

                if (adjustedIndex % 2 == 0) {
                    insertHint(productElement, "content_desktop_hint");
                }
            } else {
                if (adjustedIndex % 2 == 0) {
                    insertHint(productElement, "content_mobile_hint");
                }
                if (adjustedIndex % 3 == 0) {
                    insertHint(productElement, "content_desktop_hint");
                }
            }
        });
    }
}
