import TomSelect from 'tom-select';
import { productSearchResult } from "./product_search_result";
import { debounce } from "../application";
import { Spinner } from "spin.js";
import { opts } from "../src/spinner_opts";

document.addEventListener("turbo:load", function () {
    initProductSearchBar("header-product-search");
    initProductSearchBar("homepage-product-search");

    initializeProductSearchBars(document);
});

document.addEventListener("turbo:frame-load", function () {
    initializeProductSearchBars(document);
});

function initializeProductSearchBars(element) {
    const mobileSearchModalInputs = Array.from(element.getElementsByClassName('mobile-modal-search-input'));

    mobileSearchModalInputs.forEach(mobileSearchModalInput => {
        initMobileProductSearchBar(mobileSearchModalInput);
    });
}

function initProductSearchBar(element_id) {
    if (document.getElementById(element_id)) {
        const product_search_bar = new TomSelect(`#${element_id}`, {
            valueField: 'id',
            labelField: 'text',
            searchField: [],
            diacritics: true,
            preload: true,
            shouldLoad: function (query) {
                return query.length > 1;
            },
            // fetch remote data https://caniuse.com/fetch
            load: function (query, callback) {
                product_search_bar.clearOptions();
                let url = '/search.json?search[term]=' + encodeURIComponent(query);
                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                    }).catch(() => {
                        callback();
                    });

            },
            onItemAdd: function (value, item) {
                product_search_bar.clear();
            },
            // custom rendering functions for options and items
            render: {
                option: function (item, escape) {
                    return productSearchResult(item.text, item.heading, item.thumbnail_url, item.variant_type);
                },
                item: function (item, escape) {
                    window.location.href = item.url;
                    return null;
                }
            }
        });

        product_search_bar.addOption({ text: "Search All", heading: "test", thumbnail_url: "" }, false);

        product_search_bar.on("dropdown_open", function () {
            // Get the input element inside the TomSelect dropdown
            let input = document.querySelector(`#${element_id}-ts-control`);
            // Attach the keydown event listener
            input.addEventListener("keydown", function (event) {
                // Check if the pressed key is "Enter"
                if (event.key === "Enter") {
                    let value = input.value;
                    product_search_bar.clearOptions();
                    product_search_bar.disable();
                    window.location.href = searchUrl(value);

                    event.preventDefault();
                }
            });
        });
    }
}

// Store debounced handlers for each input element. 
// Ensures we don't add multiple listeners to the same input element
const inputHandlers = new WeakMap();

function initMobileProductSearchBar(textInput) {
    if (!textInput) return;

    // Remove existing handler if it exists
    const existingHandler = inputHandlers.get(textInput);
    if (existingHandler) {
        textInput.removeEventListener("input", existingHandler);
    }

    // Create new debounced handler
    const debouncedHandler = debounce(function () {
        handleMobileSearchInput(textInput);
    }, 400);

    // Store the new handler
    inputHandlers.set(textInput, debouncedHandler);

    // Add the new listener
    textInput.addEventListener("input", debouncedHandler);
}

function handleMobileSearchInput(textInput) {
    if (textInput.value.length < 2) {
        return;
    }

    let form = textInput.closest('form');
    let resultsDiv = document.getElementById(textInput.dataset.resultsDivId);
    let searchResults = resultsDiv.querySelector('.search-result-content');

    if (searchResults) {
        new Spinner(opts).spin(searchResults);
        searchResults.classList.add("contrast-50");
    }

    form.requestSubmit();
}

function searchUrl(query) {
    if (query == "") {
        return "/products"
    } else {
        return `/products?product_filter[search]=${encodeURIComponent(query)}`;
    }
}

