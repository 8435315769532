
document.addEventListener('turbo:load', ()=> {
    moveShowMoreButtons(document);
})

document.addEventListener('turbo:frame-render', (event)=> {
    moveShowMoreButtons(event.target);
})

function moveShowMoreButtons(containerElement) {
    let elementsToMove = containerElement.querySelectorAll('.show-more-button');

    elementsToMove.forEach(element => {
        const previousElement = element.previousElementSibling;

        if (previousElement !== null) {
            previousElement.appendChild(element);
        }
    });
}