import Sortable from 'sortablejs';

document.addEventListener('turbo:load', function() {
    let guidesList = document.getElementById('sort-guides');

    if (guidesList) {
        let csrfToken = document.querySelector("[name='csrf-token']").content

        Sortable.create(guidesList, {
            onEnd: function(/**Event*/evt) {
                let draggedItem = evt.item;  // dragged HTMLElement
                let data_guide = draggedItem.dataset.guide

                let newOrder = evt.newIndex;

                let formData = new FormData();
                formData.append('guide[position]', newOrder);
                
                fetch('/guide/' + data_guide, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": csrfToken
                    },
                    body: formData
                });
            }
        });
    }
});