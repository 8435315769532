function isNativeApp() {
  return document.documentElement.hasAttribute('data-turbo-native-app');
}

function setupNativeShare() {
  if (!isNativeApp()) return;

  document.querySelectorAll('.trigger-native-share').forEach(element => {
    element.addEventListener('click', () => {
      window.webkit.messageHandlers.nativeApp.postMessage({name: "trigger_share", url: element.dataset.shareUrl});
    });
  });
}

document.addEventListener("turbo:load", setupNativeShare);
document.addEventListener('turbo:frame-load', setupNativeShare);


