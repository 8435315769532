import TomSelect from 'tom-select';
import {ingredientSearchResult} from "./ingredient_search_result";

document.addEventListener("turbo:load", function() {
  if (document.getElementById("ingredient-database-search")) {
    const ingredient_search_bar = new TomSelect('#ingredient-database-search',{
    valueField: 'id',
    labelField: 'text',
    searchField: [],
    diacritics: true,
    preload: true,
    // fetch remote data https://caniuse.com/fetch
    load: function(query, callback) {
      ingredient_search_bar.clearOptions();
      let url = '/ingredients/search.json?search[term]=' + encodeURIComponent(query);
      fetch(url)
        .then(response => response.json())
        .then(json => {
          callback(json);
        }).catch(()=>{
          callback();
        });

    },
    // custom rendering functions for options and items
    render: {
      option: function(item, escape) {
        return ingredientSearchResult(item.text);
   },
     item: function(item, escape) {
        window.location.href = item.url;
        return null;
      }
    }
  });
  }
});
