document.addEventListener("turbo:load", function () {
  initializeBottomDrawers();
});

document.addEventListener("turbo:frame-render", function () {
  initializeBottomDrawers();
});

document.addEventListener("turbo:after-stream-render", function () {
  initializeBottomDrawers();
});

function initializeBottomDrawers() {
  const bottomDrawerElements = document.querySelectorAll(".bottom-drawer");

  bottomDrawerElements.forEach((bottomDrawer) => {
    createBottomDrawer(bottomDrawer);
  });
}

function createBottomDrawer(bottomDrawer) {
  let overlay = bottomDrawer.querySelector(".overlay");
  let content = bottomDrawer.querySelector(".drawer-content");
  let body = bottomDrawer.querySelector(".body");
  let dragIcon = bottomDrawer.querySelector(".header");

  let maxHeight = bottomDrawer.dataset.maxHeight; // Maximum height before allowing internal scrolling
  // Updated to support mobile and desktop heights
  let defaultHeightMobile = bottomDrawer.dataset.defaultHeightMobile;
  let defaultHeightDesktop = bottomDrawer.dataset.defaultHeightDesktop;
  let triggerButtonId = bottomDrawer.dataset.triggerId;
  let triggerButtonIds = triggerButtonId.split(",").map((id) => id.trim()); // Split by comma and trim spaces

  let dismissDrawerButton = bottomDrawer.querySelector(
    ".dismiss-drawer-button",
  );

  let isDragging = false,
    startY,
    startHeight;

  // Function to determine the default height based on screen width
  let getDefaultHeight = () =>
    window.innerWidth <= 768 ? defaultHeightMobile : defaultHeightDesktop;

  let toggleButtons = (showSheet) => {
    triggerButtonIds.forEach((id) => {
      const button = document.getElementById(id);

      if (button) {
        const downIcon = button.querySelector(".down-icon");
        const upIcon = button.querySelector(".up-icon");

        if (downIcon) downIcon.classList.toggle("hidden", showSheet);
        if (upIcon) upIcon.classList.toggle("hidden", !showSheet);
      }
    });
  };

  let updateHeight = (height) => {
    if (height > maxHeight) {
      height = maxHeight;
      removeContentDragListener();
    }

    content.style.height = `${height}vh`;
    bottomDrawer.classList.toggle("fullscreen", height === 100);
  };

  const appendToBody = content.classList.contains("append-modal-to-body");

  // Store the original parent and sibling
  const originalParent = bottomDrawer.parentNode;
  const originalNextSibling = bottomDrawer.nextSibling;

  let showSheet = () => {
    if (appendToBody) {
      // Remove other elements with matching data-trigger-id
      const currentTriggerId = bottomDrawer.dataset.triggerId;
      document.querySelectorAll(`[data-trigger-id="${currentTriggerId}"]`).forEach(element => {
        if (element !== bottomDrawer) {
          element.remove();
        }
      });
      document.body.appendChild(bottomDrawer);
    }

    bottomDrawer.classList.remove("hidden");
    body.classList.remove("hidden"); // Hidden class is needed to we don't lazy-load turbo frames until the sheet is shown

    // Allow the browser 10ms to repaint the bottomDrawer after we remove the hidden class from it.
    // We need to keep it hidden before this for performance, as iOS Safari crashes if we have to many opacity: 0 divs that aren't display: none
    setTimeout(() => {
      bottomDrawer.classList.add("show");
    }, 10);

    toggleButtons(true);

    // Updating sheet height with default height based on screen size
    let defaultHeight = getDefaultHeight();
    updateHeight(defaultHeight);

    addContentDragListener();

    document.body.style.overflow = "hidden";
  };

  // Expose the showSheet method
  bottomDrawer.showSheet = showSheet;

  let hideSheet = () => {
    bottomDrawer.classList.remove("show");
    body.classList.add("hidden");

    // Delay adding the hidden class so our hide transition has time to finish
    setTimeout(() => {
      bottomDrawer.classList.add("hidden");

      if (appendToBody) {
        if (originalNextSibling) {
          originalParent.insertBefore(bottomDrawer, originalNextSibling);
        } else {
          originalParent.appendChild(bottomDrawer);
        }

        // Remove other elements with matching data-trigger-id
        const currentTriggerId = bottomDrawer.dataset.triggerId;
        document.querySelectorAll(`[data-trigger-id="${currentTriggerId}"]`).forEach(element => {
          if (element !== bottomDrawer) {
            element.remove();
          }
        });
      }
    }, 300);

    toggleButtons(false);

    body.scrollTop = 0; // scroll body to top so if they re-open it is positioned correctly
    document.body.style.overflow = "auto";
  };

  let dragStart = (e) => {
    isDragging = true;
    bottomDrawer.classList.add("dragging");
    //recording intitial y position and sheet height
    startY = e.pageY || e.touches?.[0].pageY;
    startHeight = parseInt(content.style.height);
  };

  let dragging = (e) => {
    if (e.cancelable) {
      e.preventDefault();
    } // fix lag

    //return if isDragging is false
    if (!isDragging) return;

    //calculating new height of sheet by using starty and start height
    //calling updateHeight function with new height as argument

    let delta = startY - (e.pageY || e.touches?.[0].pageY);
    let newHeight = startHeight + (delta / window.innerHeight) * 100;

    updateHeight(newHeight);
  };

  let dragStop = (e) => {
    isDragging = false;
    bottomDrawer.classList.remove("dragging");

    //setting sheet height based on the sheet current height or position
    let sheetHeight = parseInt(content.style.height);

    // Support quick swipes down to dismiss the drawer.
    // If they swipe down by more than 10 let's just hide the drawer right away
    if (startHeight - sheetHeight > 10) {
      hideSheet();
      return;
    }

    let defaultHeight = getDefaultHeight();

    sheetHeight < 29
      ? hideSheet()
      : sheetHeight > 60 && sheetHeight > defaultHeight
        ? updateHeight(maxHeight)
        : updateHeight(defaultHeight);
  };

  dragIcon.addEventListener("mousedown", dragStart, { passive: true });
  dragIcon.addEventListener("mousemove", dragging);
  document.addEventListener("mouseup", dragStop, { passive: true });

  content.addEventListener("touchstart", dragStart, { passive: true });
  content.addEventListener("touchmove", dragging, { passive: false }); // Keep this non-passive as it uses preventDefault

  dragIcon.addEventListener("touchstart", dragStart, { passive: true });
  dragIcon.addEventListener("touchmove", dragging, { passive: false }); // Keep this non-passive as it uses preventDefault
  document.addEventListener("touchend", dragStop, { passive: true });

  body.addEventListener("scroll", () => {
    // Use a small threshold instead of strict 0 for better reliability
    const scrollTopThreshold = 5;
    if (body.scrollTop <= scrollTopThreshold) {
      addContentDragListener();
    }
  }, { passive: true });

  let addContentDragListener = () => {
    content.addEventListener("touchstart", dragStart, { passive: true });
    content.addEventListener("touchmove", dragging, { passive: false }); // Keep this non-passive as it uses preventDefault
  };

  let removeContentDragListener = () => {
    content.removeEventListener("touchstart", dragStart);
    content.removeEventListener("touchmove", dragging);
  };

  triggerButtonIds.forEach((id) => {
    let button = document.getElementById(id);
    if (button) {
      button.addEventListener("click", showSheet);

      // Since we refresh the trigger buttons with a turbo frame, we need to re-add the listener when they've been replaced
      document.addEventListener("turbo:frame-load", function () {
        let refreshedButton = document.getElementById(id);
        if (refreshedButton) {
          refreshedButton.addEventListener("click", showSheet);
        }
      });
    }
  });

  dismissDrawerButton.addEventListener("click", hideSheet);
  overlay.addEventListener("click", hideSheet);
}
