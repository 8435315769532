document.addEventListener("turbo:load", function () {
  const form = document.querySelector(
    "form.change-should-update-continue-link",
  );
  if (form) {
    const updateLink = () => {
      const formData = new FormData(form);
      formData.delete("authenticity_token");
      formData.delete("_method");

      const params = new URLSearchParams(formData).toString();
      const link = document.querySelector("a.continue-link");
      Turbo.session.preloader.stop();
      link.href = `${link.dataset.baseHref}?${params}`;
      Turbo.session.preloader.start();
    };

    form.addEventListener("change", updateLink);
  }

  const newOnboardingStepForm = document.getElementById("new_onboarding_step");
  const editOnboardingStepForm = document.querySelector("[id^='edit_onboarding_step']");
  if (newOnboardingStepForm) {
    newOnboardingStepForm.addEventListener("change", function () {
      newOnboardingStepForm.requestSubmit();
    });
  }

  if (editOnboardingStepForm) {
    editOnboardingStepForm.addEventListener("change", function () {
      editOnboardingStepForm.requestSubmit();
    });
  }
});
