import "@hotwired/turbo-rails";

import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import collapse from '@alpinejs/collapse';
import sticky from 'alpinejs-sticky'

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(sticky)

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import Cookies from "js-cookie";
window.Cookies = Cookies;

import "./src/alpine_tooltip_plugin";
import "./src/product_selectbox";
import "./src/move_show_more_buttons";
import "./src/product_search_bar";
import "./src/simple_selects";
import "./src/star_rating";
import "./src/product_filters";
import "./src/copytext";
import "./src/text_remaining";
import "./src/position_tools_dropdown";
import "./src/ingredient_search_bar";
import "./src/brand_filters";
import "./src/toggle_product_filters";
import "./src/toggle_routine_insights";
import "./src/update_cached_csrf_tokens";
import "./src/compare_select_modal";
import "./src/onboarding";
import "./src/sort_shelf";
import "./src/table_of_contents";
import "./src/variant_name_display";
import "./src/analyzer_confetti";
import "./src/detect_ingredient_count";
import "./src/ingredient_image_upload";
// Routine creator related
import "./src/add_routine_product";
import "./src/sort_routine";
import "./src/frequency_select";

import "./src/detect_duplicate";
import "./src/photo_upload_preview";
import "./src/hide_sticky_navbar";
import "./src/mobile_search_modal";
import "./src/routine_product_notes";
import "./src/product_index_ad_insert";
import "./src/view_transitions";
import "./src/bottom_drawer";
import "./src/mobile_modal_adhesion_adjuster";
import "./src/handle_pushed_states";
import "./src/user_product_search_bar";
import "./src/image_gallery";
import "./src/community_tabs";
import "./src/add_extra_logged_product";
import "./src/time_ago_in_words";
import "./src/sort_guide";
import "./controllers";
import "./src/decorate_ingredient_lists";
import "./src/prevent_clear_snapshot_cache";
import "./src/trigger_native_haptic";
import "./src/charts/skin_rating_chart";
import "./src/trigger_native_share";
document.addEventListener("DOMContentLoaded", () => {
  Alpine.start();
});

document.addEventListener("turbo:load", function () {
  let userTimeZone =
    new Intl.DateTimeFormat().resolvedOptions().timeZone ||
    "America/Los_Angeles";
  Cookies.set("user_time_zone", userTimeZone);

  addEventListener("turbo:before-stream-render", (event) => {
    const fallbackToDefaultActions = event.detail.render;

    event.detail.render = function (streamElement) {
      if (streamElement.action == "redirect") {
        window.location.href = streamElement.getAttribute("location");
      } else {
        fallbackToDefaultActions(streamElement);
      }
    };
  });
});

addEventListener("turbo:before-frame-render", (event) => {
  if (document.startViewTransition && event.target.classList.contains('should-view-transition')) {
    const originalRender = event.detail.render
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(() => originalRender(currentElement, newElement))
    }
  }
})


export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

window.sendAnalyticsEvent = function sendAnalyticsEvent(action) {
  gtag("event", action, {
    current_path: window.location.pathname,
  });
};

// Add an after-stream-render event
// Taken from https://discuss.hotwired.dev/t/event-to-know-a-turbo-stream-has-been-rendered/1554/24
const afterRenderEvent = new Event("turbo:after-stream-render");
addEventListener("turbo:before-stream-render", (event) => {
  const originalRender = event.detail.render;

  event.detail.render = function (streamElement) {
    originalRender(streamElement);
    document.dispatchEvent(afterRenderEvent);
  };
});
