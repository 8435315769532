import { BridgeComponent, BridgeElement } from "@hotwired/strada"

export default class extends BridgeComponent {
    static component = "double-button"
    static targets = ["first", "second"]

    connect() {
        super.connect()

        if (this.hasFirstTarget) {
            const element = new BridgeElement(this.firstTarget)
            const image = element.bridgeAttribute("ios-image")

            this.send("first", {title: element.title, action: element.bridgeAttribute("action"), url: element.bridgeAttribute("url"), image}, () => {
                new BridgeElement(this.firstTarget).click()
            })
        }

        if (this.hasSecondTarget) {
            const element = new BridgeElement(this.secondTarget)
            const image = element.bridgeAttribute("ios-image")

            this.send("second", {title: element.title, action: element.bridgeAttribute("action"), url: element.bridgeAttribute("url"), image}, () => {
                new BridgeElement(this.secondTarget).click()
            })
        }
    }
}