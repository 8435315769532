import {debounce} from "../application";

document.addEventListener("turbo:load", function() {

  if(document.getElementById("tools-modal-button")) {
    const tools = document.getElementById("tools-modal-button");

    tools.addEventListener("mouseenter", debounce(function () {
      let modal_left = tools.getBoundingClientRect().left;
      document.getElementById("tools-modal").style.left = `${modal_left - 100}px`;
    }), 800);

    let products_button = document.getElementById("skincare-modal-button");
    products_button.addEventListener("mouseenter", debounce(function (e) {
      let modal = document.getElementById("skincare-modal");
      let modal_left = products_button.getBoundingClientRect().left;

      modal.style.left = `${modal_left - 100}px`;
    }), 800);
  };
});

