import TomSelect from 'tom-select';
import { productSearchResult } from "./product_search_result";
import {debounce} from "../application";

document.addEventListener("turbo:load", function() {
  if (document.getElementById("product-add")) {
    const product_add_bar = new TomSelect("#product-add",{
      valueField: 'id',
      labelField: 'text',
      searchField: [],
      diacritics: true,
      preload: true,
      closeAfterSelect: true,
      loadThrottle: 200,
      maxItems: 1,
      onChange: function(value){
        document.activeElement.blur();
      },
      // fetch remote data https://caniuse.com/fetch
      load: function(query, callback) {
        product_add_bar.clearOptions();

        let url = productSearchQueryUrl(query, true);

        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json);
          }).catch(()=>{
            callback();
          });

      },
      // custom rendering functions for options and items
      render: {
        option: function(item, escape) {
          return productSearchResult(item.text, item.heading, item.thumbnail_url, item.variant_type);
         },
        item: function(item, escape) {
          document.getElementById("add-to-routine-buttons").classList.remove("hidden");
          document.getElementById("submit-product-cta").classList.add("hidden");

          return productSearchResult(item.text, item.heading, item.thumbnail_url, item.variant_type);
        }
      }
    });

    let ownedOnlyButton = document.getElementById('desktopAddRoutineProductSwitchOwnedButton');

    if(ownedOnlyButton) {
        ownedOnlyButton.addEventListener("click",  debounce(function() {
            refreshSearchBar(product_add_bar);
        }, 250));
    }
  }
});

export function productSearchQueryUrl(query = '', includeOwnedOnlyParam = false) {
    const ownedOnly = includeOwnedOnlyParam ? Cookies.get('routineCreatorOwnedOnly') : '';

    let url = '/search/products_search.json';

    const queryParams = [];

    if (includeOwnedOnlyParam && ownedOnly) {
        queryParams.push(`search[owned]=${ownedOnly}`);
    }

    queryParams.push(`search[term]=${encodeURIComponent(query)}`);

    if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
    }

    return url;
}


export function refreshSearchBar(search_bar) {
    setTimeout(function() {
        search_bar.clearOptions();
        search_bar.load(); // this order will clear select options and refresh from remote: https://github.com/orchidjs/tom-select/issues/338
    }, 100); // delay by 100 ms so that the routineCreatorOwnedOnly is set to the correct value

}