import TomSelect from 'tom-select';
import {productSearchResult} from "./product_search_result";
import {productSearchQueryUrl, refreshSearchBar} from "./add_routine_product";

document.addEventListener("turbo:load", function() {
    let search_url = null;

    initializeCompareSelects();

    if (document.getElementById("holy-grail-product-select")) {
        productSelectBar("#holy-grail-product-select", search_url)
    }

    if (document.getElementById("product-quick-add")) {
        productSelectBar("#product-quick-add", search_url)
    }

    if (document.getElementById("dupe-search-bar")) {
        productSelectBar("#dupe-search-bar", search_url)
    }

    //  Product Edit Page
    if (document.getElementById("product-new-version-select")) {
        let brand_id = document.getElementById("product-new-version-select").dataset.brandId
        let exclude_product_id = document.getElementById("product-new-version-select").dataset.productId

        search_url = `/search/products_search.json?search[brand_id]=${brand_id}&search[exclude_product_id]=${exclude_product_id}&search[term]=`

        productSelectBar("#product-new-version-select", search_url)
    }

    if (document.getElementById("product-new-group-select")) {
        let brand_id = document.getElementById("product-new-group-select").dataset.brandId
        let exclude_group_id = document.getElementById("product-new-group-select").dataset.groupId

        search_url = `/search/products_search.json?search[brand_id]=${brand_id}&search[group_only]=true&search[exclude_group_id]=${exclude_group_id}&search[term]=`

        productSelectBar("#product-new-group-select", search_url)
    }
});


function initializeCompareSelects() {
    if (document.getElementById("product-a-select")) {
        productSelectBar("#product-a-select", null)
    }
    if (document.getElementById("product-b-select")) {
        productSelectBar("#product-b-select", null)
    }
    if (document.getElementById("product-c-select")) {
        productSelectBar("#product-c-select", null)
    }
    if (document.getElementById("product-d-select")) {
        productSelectBar("#product-d-select", null)
    }
}

function productSelectBar(id, search_url, includeOwnedOnlyParam = false) {
    let search_bar = new TomSelect(id,{
        valueField: 'id',
        labelField: 'text',
        searchField: [],
        diacritics: true,
        preload: true,
        closeAfterSelect: true,
        loadThrottle: 200,
        maxItems: 1,
        onChange: function(value){
            document.activeElement.blur();
        },
        // fetch remote data https://caniuse.com/fetch
        load: function(query, callback) {
            search_bar.clearOptions();

            let url = simpleSearchUrl(query, search_url) || productSearchQueryUrl(query, includeOwnedOnlyParam);

            fetch(url)
                .then(response => response.json())
                .then(json => {
                    callback(json);
                }).catch(()=>{
                callback();
            });

        },
        // custom rendering functions for options and items
        render: {
            option: function(item, escape) {
                return productSearchResult(item.text, item.heading, item.thumbnail_url, item.variant_type);
            },
            item: function(item, escape) {
                return productSearchResult(item.text, item.heading, item.thumbnail_url, item.variant_type);
            }
        }
    });

    return search_bar;
}

function setupOwnedOnlyButtonClickListener(search_bar) {
    let ownedOnlyButtons = document.querySelectorAll('.owned-products-only-toggle');

    ownedOnlyButtons.forEach(ownedOnlyButton => {
        ownedOnlyButton.addEventListener("click", function() {
            refreshSearchBar(search_bar);
        });
    });
}

function simpleSearchUrl(query = '', search_url) {
    if(!search_url) {
        search_url = '/search/products_search.json?search[term]=';
    }

    return search_url + encodeURIComponent(query);
}