document.addEventListener("turbo:load", function() {
  handleStarRating();
});

document.addEventListener("turbo:frame-render", function() {
  handleStarRating();
});

function handleStarRating() {
  let starRatingSections = document.querySelectorAll('.star-rating-section');

  function setInitialStars(ratingStarsContainer, reviewRatingField) {
    let ratingValue = reviewRatingField.value;

    if (ratingValue) {
      Array.from(ratingStarsContainer.children).forEach(star => {
        if (star.getAttribute('data-value') <= ratingValue) {
          star.classList.add("text-yellow-500");
        } else {
          star.classList.remove("text-yellow-500");
        }
      });
    }
  }

  function setupListeners(ratingStarsContainer, reviewRatingField, postReviewElement, starRatingForm) {
    ratingStarsContainer.addEventListener("click", function (event) {
      if (event.target.classList.contains('rating-star')) {
        let clickedValue = event.target.getAttribute('data-value');

        reviewRatingField.value = clickedValue;
        reviewRatingField._x_model.set(clickedValue);

        if (postReviewElement) {
          postReviewElement.classList.remove("hidden");
        }

        Array.from(ratingStarsContainer.children).forEach(s => {
          if (s.getAttribute('data-value') <= clickedValue) {
            s.classList.add("text-yellow-500");
          } else {
            s.classList.remove("text-yellow-500");
          }
        });
      }

      if (starRatingForm) {
        starRatingForm.submit();
      }
    });
  }

  starRatingSections.forEach(starRatingSection => {
    let ratingStarsContainer = starRatingSection.querySelector('.star-rating-list');
    let reviewRatingField = starRatingSection.querySelector('.review-rating-field');
    let postReviewElement = starRatingSection.querySelector('.post-review');
    let starRatingForm = starRatingSection.querySelector('.star-rating-form');

    if (reviewRatingField) {
      setInitialStars(ratingStarsContainer, reviewRatingField);
    }

    if (ratingStarsContainer) {
      setupListeners(ratingStarsContainer, reviewRatingField, postReviewElement, starRatingForm);
    }
  });
}