import TomSelect from 'tom-select';

document.addEventListener("turbo:load", function() {
    const selects = [
        { id: "skin-concern-select", options: { plugins: ['remove_button'] } },
        { id: "skin-type-select", options: {} },
        { id: "category-select", options: { maxOptions: null } },
        { id: "variant-select", options: {} },
        { id: "country-select", options: { sortField: 'text' } },
        { id: "post-tag-select", options: { allowEmptyOption: true, maxItems: 1 } },
        { id: "merchant-id-select", options: { maxItems: 1 } }
    ];

    selects.forEach(({ id, options }) => {
        const element = document.getElementById(id);
        if (element) {
            new TomSelect(`#${id}`, options);
        }
    });

    let brandSelectBar = null;
    if (document.getElementById("brand-select-bar")) {
        brandSelectBar = new TomSelect('#brand-select-bar', {
            valueField: 'id',
            labelField: 'text',
            searchField: [],
            diacritics: true,
            preload: true,
            closeAfterSelect: true,
            loadThrottle: 200,
            maxItems: 1,
            plugins: ['remove_button'],
            load: function(query, callback) {
                let url = '/brands/search.json?search[term]=' + encodeURIComponent(query);

                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        this.clearOptions(); // clear options from previous queries
                        callback(json);
                    }).catch(() => {
                        callback();
                    });
            },
            render: {
                option: function(item, escape) {
                    const flagPathHtml = item.flag_path ? `<img class="mr-3 w-8 object-contain" src="${item.flag_path}" />` : '';
                    const crueltyFreeHtml = item.cruelty_free ? escape(item.cruelty_free) : '';

                    return `<div class="py-3 font-header flex items-center border-b border-blue-gray-50 w-full">
                                <div class='flex flex-wrap py-1.5'>
                                    ${flagPathHtml}
                                    <div class="text-blue-gray-800 font-medium leading-none pt-0.5">
                                        ${escape(item.text)}
                                    </div>
                                </div>
                                <div class="ml-auto flex text-blue-gray-500 text-sm font-light">
                                    ${crueltyFreeHtml}
                                </div>
                            </div>`;
                }
            }
        });
    }

    if(brandSelectBar) {
        // Fetch the preselected item, and add to the control
        if (typeof brandId != "undefined") {
            if (brandId != "null" && brandId != null) {
                brandSelectBar.addOption({ id: brandId, text: brandName});
                brandSelectBar.addItem(brandId, true);
            }
        }
    }
});