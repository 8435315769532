const originalClearSnapshotCache = Turbo.navigator.view.clearSnapshotCache;

document.addEventListener("turbo:submit-start", (event) => {
  const form = event.target;
  const preventClear = form.dataset.turboPreventClearSnapshotCache === "true" || form.dataset.turboPreventClearSnapshotCache === true;
  
  if (preventClear) {
    Turbo.navigator.view.clearSnapshotCache = () => {};
  } else {
    Turbo.navigator.view.clearSnapshotCache = originalClearSnapshotCache;
  }
});
