document.addEventListener("turbo:load", function () {
  styleIngredientsOnPage();
  setIngredientStepBadge();
  setIngredientActivePercentages();
  highlightFungalAcneTriggers();
  highlightSharedIngredients();
});

document.addEventListener("turbo:frame-load", function () {
  styleIngredientsOnPage();
  setIngredientStepBadge();
  setIngredientActivePercentages();
  highlightFungalAcneTriggers();
  highlightSharedIngredients();
});

async function fetchUserIngredientPreferences() {
  const userElement = document.querySelector("[data-user-id]");
  const userId = userElement ? userElement.dataset.userId : null;
  
  if (!userId) {
    return { liked: new Set(), disliked: new Set() }; // User is not logged in
  }

  const likedKey = `likedIngredientIds_${userId}`;
  const dislikedKey = `dislikedIngredientIds_${userId}`;
  const updatedAtKey = `ingredientsUpdatedAt_${userId}`;

  const likedIngredientIds = JSON.parse(localStorage.getItem(likedKey)) || [];
  const dislikedIngredientIds = JSON.parse(localStorage.getItem(dislikedKey)) || [];
  const lastUpdated = localStorage.getItem(updatedAtKey);
  const userIngredientsElement = document.querySelector(
    "[data-user-ingredients-updated-at]",
  );
  if (!userIngredientsElement) {
    return { liked: new Set(), disliked: new Set() }; // User is not logged in
  }

  const serverTimestamp = userIngredientsElement
    ? new Date(
        userIngredientsElement
          .getAttribute("data-user-ingredients-updated-at")
          .trim(),
      )
    : null;

  if (
    !lastUpdated ||
    (serverTimestamp && new Date(lastUpdated) < serverTimestamp)
  ) {
    const response = await fetch("/api/user/ingredients");
    const data = await response.json();
    localStorage.setItem(likedKey, JSON.stringify(data.liked));
    localStorage.setItem(dislikedKey, JSON.stringify(data.disliked));
    localStorage.setItem(updatedAtKey, serverTimestamp.toISOString());
    return { liked: new Set(data.liked), disliked: new Set(data.disliked) };
  }

  return {
    liked: new Set(likedIngredientIds),
    disliked: new Set(dislikedIngredientIds),
  };
}

async function styleIngredientsOnPage() {
  const { liked, disliked } = await fetchUserIngredientPreferences();

  document.querySelectorAll("[data-ingredient-id]").forEach((ingredient) => {
    const ingredientId = parseInt(ingredient.dataset.ingredientId, 10);
    const userIngredientStyle = ingredient.dataset.userIngredientStyle;

    if (liked.has(ingredientId)) {
      if (userIngredientStyle === "medium") {
        ingredient.classList.add("bg-emerald-200/50");
      } else {
        ingredient.classList.add("bg-emerald-100/40");
      }
    } else if (disliked.has(ingredientId)) {
      if (userIngredientStyle === "medium") {
        ingredient.classList.add("bg-red-200/50");
      } else {
        ingredient.classList.add("bg-red-100/50");
      }
    }
  });
}

function setIngredientStepBadge() {
  document
    .querySelectorAll("[data-ingredient-list-container]")
    .forEach((container) => {
      container
        .querySelectorAll("[data-ingredient-order-badge]")
        .forEach((badge, index) => {
          badge.innerText = index + 1;
        });
    });
}

function highlightFungalAcneTriggers() {
  if (document.querySelector('[data-highlight-fungal-acne="true"]')) {
    document
      .querySelectorAll('[data-fungal-acne-highlightable="true"]')
      .forEach((element) => {
        element.classList.add("!bg-orange-100");
      });
  }
}

function highlightSharedIngredients() {
  const sharedIngredientsElement = document.querySelector(
    "[data-shared-ingredients]",
  );
  if (sharedIngredientsElement) {
    const sharedIngredients = JSON.parse(
      sharedIngredientsElement.getAttribute("data-shared-ingredients"),
    );
    sharedIngredients.forEach(({ id }) => {
      document
        .querySelectorAll(`[data-shared-highlightable="${id}"]`)
        .forEach((element) => {
          element.classList.add("!bg-indigo-100");
        });
    });
  }
}

function setIngredientActivePercentages() {
  document
    .querySelectorAll("[data-ingredient-list-container]")
    .forEach((container) => {
      const activePercentagesElement = container.querySelector(
        "[data-ingredient-active-percentages]",
      );
      if (activePercentagesElement) {
        const activePercentages = JSON.parse(
          activePercentagesElement.getAttribute(
            "data-ingredient-active-percentages",
          ),
        );
        activePercentages.forEach(({ id, active_percentage }) => {
          const ingredientNameElement = container.querySelector(
            `[data-ingredient-name-for="${id}"]`,
          );
          if (
            ingredientNameElement &&
            !ingredientNameElement.innerText.includes("%")
          ) {
            ingredientNameElement.innerText += ` ${active_percentage}`;
          }
        });
      }
    });
}
