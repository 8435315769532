import {debounce} from "../application";
import confetti from 'canvas-confetti';

document.addEventListener("turbo:load", debounce(function() {
  if (document.getElementById("analysis_details")) {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  }
}, 250));