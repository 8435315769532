import Sortable from 'sortablejs';

document.addEventListener("turbo:load", function() {
  if (document.getElementById("edit_shelf")) {
    let shelf = document.getElementById('edit_shelf');
    let data_shelf = shelf.dataset.shelf;

    createShelfSortable(shelf, data_shelf);
  }
});

function createShelfSortable(element, data_shelf) {
    let csrfToken = document.querySelector("[name='csrf-token']").content

    new Sortable(element, {
        handle: '.sort-handle',
        animation: 150,
        easing: "cubic-bezier(1, 0, 0, 1)",
        // Event when you move an item in the list or between lists
        onEnd: function(/**Event*/evt) {
            let productElement = evt.item;  // dragged HTMLElement
            let inventoryProductId = productElement.dataset.inventoryProductId;
            let formData = new FormData();
            formData.append('new_position', evt.newIndex);

            fetch('/shelf/products/'+ inventoryProductId, {
                method: "PATCH",
                headers: {
                    "X-CSRF-Token": csrfToken
                },
                body: formData
            });
        }
    });
}