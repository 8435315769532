import { opts } from "../src/spinner_opts";
import { Spinner } from "spin.js";
import TomSelect from "tom-select";
import { ingredientSearchResult } from "./ingredient_search_result";
import { debounce } from "../application";

class ProductFilter {
  constructor() {
    this.filterForm = document.getElementById("product-index-filter");
    this.countrySelectBar = document.getElementById("filter-country-select");
    this.vendorSelectBar = document.getElementById("filter-vendor-select");
    this.ingredientSelectBar = document.getElementById("ingredient-select-bar");
    this.excludedIngredientSelectBar = document.getElementById(
      "excluded-ingredient-select-bar",
    );
    this.hiddenBrandInput = document.getElementById("product_filter_brand_id");
    this.productsIndexContent = document.getElementById(
      "products-index-content",
    );
    this.showProductsCountElements = document.querySelectorAll(
      ".show-products-count",
    );
    this.showProductsLoadingElements = document.querySelectorAll(
      ".show-products-loading",
    );
    this.showProductsButtons = document.querySelectorAll('.show-products-button');

    // Create a debounced version of submitForm
    this.debouncedSubmitForm = debounce(() => {
      this.submitFormImpl();
    }, 100);

    if (this.filterForm) {
      this.initialize();
      this.addTurboFrameLoadListener();
    }
  }

  initialize() {
    this.setupIngredientSelectBars();
    this.attachEventListeners();
  }

  attachEventListeners() {
    const inputElements = this.filterForm.getElementsByTagName("input");
    const countryInputs = this.filterInputsByClassName(
      inputElements,
      "country-input",
    );
    const categoryInputs = this.filterInputsByClassName(
      inputElements,
      "category-input",
    );
    const priceInputs = this.filterInputsByClassName(
      inputElements,
      "price-filter-input",
    );
    const ingredientCountInputs = this.filterInputsByClassName(
      inputElements,
      "ingredient-count-input",
    );
    const spfLevelInputs = this.filterInputsByClassName(
      inputElements,
      "spf-level-input",
    );
    const uvFilterInputs = this.filterInputsByClassName(
      inputElements,
      "uv-filter-input",
    );

    Array.from(inputElements).forEach((inputElement) => {
      inputElement.addEventListener("change", (event) =>
        this.handleInputChange(
          event,
          countryInputs,
          categoryInputs,
          priceInputs,
          ingredientCountInputs,
          spfLevelInputs,
          uvFilterInputs,
        ),
      );
    });

    this.countrySelectBar.addEventListener("change", () =>
      this.handleCountrySelectChange(countryInputs),
    );
    this.vendorSelectBar.addEventListener("change", () => this.debouncedSubmitForm());
    this.ingredientSelectBar.addEventListener("change", () =>
      this.debouncedSubmitForm(),
    );
    this.excludedIngredientSelectBar.addEventListener("change", () =>
      this.debouncedSubmitForm(),
    );

    this.addClearFiltersButtonListener();
    this.addAppClearFiltersButtonListener();
    this.addRemoveBrandFilterListener();

    this.filterForm.querySelectorAll('input[type="radio"]:not([name="product_filter[order_by]"])').forEach(radio => {
      radio.addEventListener('click', this.handleRadioClick.bind(this));
    });

    this.showProductsButtons.forEach(button => {
      button.addEventListener('click', this.handleShowProductsClick.bind(this));
    });
  }

  handleRadioClick(event) {
    if (event.target.name === 'product_filter[order_by]') return;

    if (event.target.dataset.lastChecked === 'true') {
      event.target.checked = false;
      event.target.dataset.lastChecked = 'false';
    } else {
      this.filterForm.querySelectorAll(`input[name="${event.target.name}"]`).forEach(r => {
        r.dataset.lastChecked = 'false';
      });
      event.target.dataset.lastChecked = 'true';
    }
    this.debouncedSubmitForm();
  }

  addClearFiltersButtonListener() {
    const clearButtons = document.querySelectorAll(".clear-filters-button");
    clearButtons.forEach((button) => {
      button.addEventListener("click", (event) =>
        this.handleClearFiltersButtonClick(event),
      );
    });
  }

  addAppClearFiltersButtonListener() {
    const clearButtons = document.querySelectorAll(".app-clear-filters-button");
    clearButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        const form = document.getElementById('product-index-filter');
        form.reset();

        // Clear all input types except specific fields
        form.querySelectorAll('input').forEach(input => {
          const fieldName = input.name.replace(/^product_filter\[|\]$/g, '');
          if (!['page', 'only_count'].includes(fieldName)) {
            if (input.type === 'checkbox' || input.type === 'radio') {
              input.checked = false;
            } else {
              input.value = '';
            }
          }
        });

        // Clear selects except specific fields
        form.querySelectorAll('select').forEach(select => {
          const fieldName = select.name.replace(/^product_filter\[|\]$/g, '');
          if (!['page', 'only_count', 'order_by'].includes(fieldName)) {
            select.selectedIndex = 0;
            if (select.tomselect) {
              select.tomselect.clear();
            }
          }
        });

        this.debouncedSubmitForm();
      });
    });
  }

  handleClearFiltersButtonClick(event) {
    const contentDiv = event.target.closest(".drawer-content");
    if (!contentDiv) return;

    const inputs = contentDiv.querySelectorAll("input");
    inputs.forEach((input) => {
      if (input.type === "checkbox" || input.type === "radio") {
        input.checked = false;
      } else {
        input.value = "";
      }
    });

    const selects = contentDiv.querySelectorAll("select");
    selects.forEach((select) => {
      select.value = "";
      // Check if the select is a TomSelect instance and clear it
      if (select.tomselect) {
        select.tomselect.clear();
      }
    });

    this.debouncedSubmitForm(); // Assuming you want to submit the form after clearing the filters.
  }

  addTurboFrameLoadListener() {
    document.addEventListener("turbo:frame-load", () => {
      this.showProductsLoadingElements = document.querySelectorAll(
        ".show-products-loading",
      );
      this.showProductsCountElements = document.querySelectorAll(
        ".show-products-count",
      );
      this.addRemoveBrandFilterListener();
      this.addAppClearFiltersButtonListener();

      // Update the selector for the "See products" buttons
      this.showProductsButtons = document.querySelectorAll('.show-products-button');
      this.showProductsButtons.forEach(button => {
        button.addEventListener('click', this.handleShowProductsClick.bind(this));
      });
    });
  }

  setupIngredientSelectBars() {
    // Updated to not require the instance itself for clearing options
    const createTomSelect = (selector) => {
      return new TomSelect(selector, {
        valueField: "id",
        labelField: "text",
        searchField: [],
        sortField: { field: "similarity", direction: "desc" },
        diacritics: true,
        preload: true,
        closeAfterSelect: true,
        loadThrottle: 200,
        maxItems: 10,
        plugins: ["remove_button"],
        load: function (query, callback) {
          this.clearOptions();
          let url = `/ingredients/search.json?search[term]=${encodeURIComponent(query)}`;
          fetch(url)
            .then((response) => response.json())
            .then((json) => callback(json))
            .catch(() => callback());
        },
        render: {
          option: function (item, escape) {
            return ingredientSearchResult(item.text);
          },
        },
      });
    };

    // Creating TomSelect instances without passing them as arguments
    const ingredientSelectBar = createTomSelect("#ingredient-select-bar");
    const excludedIngredientSelectBar = createTomSelect(
      "#excluded-ingredient-select-bar",
    );

    // Function for fetching and adding preselected items remains the same
    const fetchAndAddPreselectedItems = (selectBar, ids) => {
      if (ids && ids !== "null") {
        ids.forEach((id) => {
          let url = `/ingredients/${id}.json`;
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              selectBar.addOption({
                id: data.id,
                text: data.name.toProperCase(),
              });
              selectBar.addItem(data.id, true);
            })
            .catch((e) => console.log(e));
        });
      }
    };

    // Fetching and adding preselected items for both select bars
    fetchAndAddPreselectedItems(
      ingredientSelectBar,
      typeof ingredientIds !== "undefined" ? ingredientIds : null,
    );
    fetchAndAddPreselectedItems(
      excludedIngredientSelectBar,
      typeof excludedIngredientIds !== "undefined"
        ? excludedIngredientIds
        : null,
    );
  }

  handleInputChange(
    event,
    countryInputs,
    categoryInputs,
    priceInputs,
    ingredientCountInputs,
    spfLevelInputs,
    uvFilterInputs,
  ) {
    if (event.target.classList.contains("country-input")) {
      this.unCheckInputsExcludingTarget(countryInputs, event.target);
      this.countrySelectBar.value = "";
      this.hiddenBrandInput.value = "";
    }

    if (event.target.classList.contains("category-input")) {
      this.unCheckInputsExcludingTarget(categoryInputs, event.target);
    }

    if (event.target.classList.contains("price-filter-input")) {
      this.unCheckInputsExcludingTarget(priceInputs, event.target);
    }

    if (event.target.classList.contains("ingredient-count-input")) {
      this.unCheckInputsExcludingTarget(ingredientCountInputs, event.target);
    }

    if (event.target.classList.contains("spf-level-input")) {
      this.unCheckInputsExcludingTarget(spfLevelInputs, event.target);
    }

    if (event.target.classList.contains("uv-filter-input")) {
      this.unCheckInputsExcludingTarget(uvFilterInputs, event.target);
    }

    this.debouncedSubmitForm();
  }

  handleCountrySelectChange(countryInputs) {
    this.hiddenBrandInput.value = "";
    this.unCheckInputsExcludingTarget(countryInputs, null);
    this.debouncedSubmitForm();
  }

  addRemoveBrandFilterListener() {
    const removeBrandFilterButton = document.getElementById(
      "remove-brand-filter-button",
    );

    if (removeBrandFilterButton) {
      removeBrandFilterButton.addEventListener("click", () => {
        this.hiddenBrandInput.value = "";
        this.debouncedSubmitForm();
      });
    }
  }

  unCheckInputsExcludingTarget(inputElements, eventTarget) {
    inputElements.forEach((inputElement) => {
      if (inputElement !== eventTarget) {
        inputElement.checked = false;
      }
    });
  }

  filterInputsByClassName(inputElements, className) {
    return Array.from(inputElements).filter((element) =>
      element.classList.contains(className),
    );
  }

  submitFormImpl() {
    // Pre-submit tasks
    this.showSpinner();
    this.updateShowProductsIndicator();
    this.disableSelectsIfNotSelected();

    this.filterForm.requestSubmit();

    // Post-submit tasks
    this.enableSelects();
    this.addOnlyCountParam();
  }

  // So that we submit the form with the only_count param by default
  // When doing a full submit this param is removed in handleShowProductsClick
  addOnlyCountParam() {
    if (!document.getElementById('product_filter_only_count')) {
      const newOnlyCountInput = document.createElement('input');
      newOnlyCountInput.type = 'hidden';
      newOnlyCountInput.id = 'product_filter_only_count';
      newOnlyCountInput.name = 'product_filter[only_count]';
      newOnlyCountInput.value = '1';
      this.filterForm.appendChild(newOnlyCountInput);
    }
  }

  // So we don't send its value instead of the selected radio button
  disableSelectsIfNotSelected() {
    if (this.countrySelectBar.value == "") {
      this.countrySelectBar.disabled = true;
    }

    if (this.vendorSelectBar.value == "") {
      this.vendorSelectBar.disabled = true;
    }
  }

  enableSelects() {
    this.countrySelectBar.disabled = false;
    this.vendorSelectBar.disabled = false;
  }

  updateShowProductsIndicator() {
    this.showProductsCountElements.forEach((element) =>
      element.classList.add("hidden"),
    );
    this.showProductsLoadingElements.forEach((element) =>
      element.classList.remove("hidden"),
    );
  }

  showSpinner() {
    if (this.productsIndexContent) {
      new Spinner(opts).spin(this.productsIndexContent);
      this.productsIndexContent.classList.add("opacity-50");
    }
  }

  handleShowProductsClick(event) {
    event.preventDefault();

    // Remove the only_count param
    let onlyCountInput = document.getElementById('product_filter_only_count');

    if (onlyCountInput) {
      onlyCountInput.remove();
    }

    // Change the turbo frame target
    this.filterForm.setAttribute('data-turbo-frame', 'products');

    // Use the debounced submit form
    this.debouncedSubmitForm();
  }
}

document.addEventListener("turbo:load", function () {
  new ProductFilter();
});

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
