document.addEventListener("turbo:load", function () {
  initializeCompareProductsModal();
});

document.addEventListener("turbo:frame-render", function () {
  initializeCompareProductsModal();
});

function initializeCompareProductsModal() {
  setupListeners();

  const submitButton = document.getElementById(
    "compare-products-mobile-modal-submit",
  );

  if (submitButton) {
    // Ensure we don't have duplicate listeners on the button
    submitButton.removeEventListener("click", handleSubmit);
    submitButton.addEventListener("click", handleSubmit);
  }
}

document.addEventListener("turbo:frame-load", function (e) {
  setTimeout(function () {
    setupListeners();
  }, 100); // Not sure why this delay is needed. for some reason listeners don't work if we set them right away
});

function setupListeners() {
  const elements = document.getElementsByClassName(
    "compare-search-product-select",
  );

  if (elements) {
    Array.from(elements).forEach((element) => {
      let buttonElement = element.querySelector(".select-button");
      buttonElement.addEventListener("click", (event) => {
        if (canSelectProduct()) {
          selectProduct(element);
        }
      });
    });
  }
}

function selectProduct(element) {
  document.getElementById("compare-search-products-modal").scrollTo({
    top: 0,
    behavior: "smooth",
    block: "end",
  });

  let container = document.getElementById("selected-products");
  container.parentElement.classList.remove("hidden");
  container.appendChild(element);

  handleSubmitButtonVisibility();
  revealDeselectProductButton(element);
  handleMaxProductsSelectedVisibility();
}

function deselectProduct(element) {
  const container = document.getElementById("search-result-content");
  container.prepend(element);

  hideDeselectProductButton(element);
  handleSubmitButtonVisibility();
  handleMaxProductsSelectedVisibility();

  const selectedProducts = document.getElementById("selected-products");

  if (selectedProducts.childElementCount == 0) {
    selectedProducts.parentElement.classList.add("hidden");
  }
}

function hideDeselectProductButton(element) {
  const deselectButton = element.querySelector(
    ".compare-search-product-remove",
  );
  deselectButton.classList.add("hidden");
}

function revealDeselectProductButton(element) {
  const deselectButton = element.querySelector(
    ".compare-search-product-remove",
  );

  deselectButton.addEventListener("click", function () {
    deselectProduct(deselectButton.parentElement);
  });

  deselectButton.classList.remove("hidden");
}

function handleMaxProductsSelectedVisibility() {
  const maxProductsSelected = document.getElementById("max-products-selected");
  const container = document.getElementById("selected-products");

  if (container.childElementCount >= container.dataset.maxSelectable) {
    maxProductsSelected.classList.remove("hidden");
  } else {
    maxProductsSelected.classList.add("hidden");
  }
}

function canSelectProduct() {
  const container = document.getElementById("selected-products");

  return container.childElementCount < container.dataset.maxSelectable;
}

function handleSubmitButtonVisibility() {
  const container = document.getElementById("selected-products");
  const submitButton = document.getElementById(
    "compare-products-mobile-modal-submit",
  );

  if (container.childElementCount > 1) {
    submitButton.classList.remove("hidden");
  } else {
    submitButton.classList.add("hidden");
  }
}

function handleSubmit() {
  const container = document.getElementById("selected-products");
  const children = container.querySelectorAll("[data-product-id]");
  const productIdArray = Array.from(children).map((child) =>
    child.getAttribute("data-product-id"),
  );

  // Iterate through select fields in the form and assign data-product-id values
  const form = document.getElementById("compare-products-form");
  const selectFields = form.querySelectorAll("select");
  let productIdIndex = 0;

  selectFields.forEach(function (selectField) {
    while (selectField.options.length > 0) {
      selectField.remove(0);
    }
  });

  for (
    let i = 0;
    i < selectFields.length && productIdIndex < productIdArray.length;
    i++, productIdIndex++
  ) {
    const selectField = selectFields[i];
    const productIdValue = productIdArray[productIdIndex];
    const newOption = new Option(productIdValue, productIdValue);

    selectField.add(newOption);
    selectField.value = productIdValue;
  }

  form.requestSubmit();
}
