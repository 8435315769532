export function productSearchResult(name, heading, thumbnail_url, variant_type) {
    let variantInfo = '';

    if (variant_type !== null && variant_type !== undefined) {
        variantInfo = `
            <div class="ml-auto text-xs bg-indigo-50 text-indigo-500 font-medium px-2">
                ${variant_type}
            </div>
        `;
    }

    return `<div class="flex items-center border-b border-blue-gray-50 w-full">
                  <img class="d-inline-block w-12 mr-2 product-thumbnail object-contain" loading='lazy' src="${thumbnail_url}" />
                  <div class='flex flex-col text-left font-header pr-4 py-1 w-full'>
                     <div class="flex items-center w-full text-blue-gray-500 font-normal text-sm tracking-tight">
                        <div>
                          ${heading}
                        </div>
                        ${variantInfo}
                     </div>
                     <div class="text-blue-gray-800 font-medium leading-none pt-0.5">
                       ${name}
                     </div>
                  </div>
              </div>`;
}
