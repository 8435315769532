['turbo:load', 'turbo:frame-render'].forEach(event => {
    document.addEventListener(event, initializeListeners);
});

function initializeListeners() {
    const addLoggedProductButtons = document.querySelectorAll(".add-logged-product")

    addLoggedProductButtons.forEach(addLoggedProductButton => {
        setupButton(addLoggedProductButton)
    })
}

function setupButton(addLoggedProductButton) {
    addLoggedProductButton.addEventListener('click', function() {
        const timeOfDay = addLoggedProductButton.dataset.timeOfDay;
        const routineId = addLoggedProductButton.dataset.routineId;
        const productId = addLoggedProductButton.dataset.productId;
        const productName = addLoggedProductButton.dataset.productName;
        const productThumbnailUrl = addLoggedProductButton.dataset.productThumbnailUrl;

        addProductToRoutine(timeOfDay, routineId, productId, productName, productThumbnailUrl)
    });
}


function addProductToRoutine(timeOfDay, routineId, productId, productName, productThumbnailUrl) {
    const routineDivId = `${timeOfDay}_routine_${routineId}`;
    const routineDiv = document.getElementById(routineDivId);
    if (routineDiv) {
        const divs = routineDiv.querySelectorAll('div');
        const lastChildDiv = divs[divs.length - 1];
        if (lastChildDiv) {
            const clonedDiv = lastChildDiv.cloneNode(true);
            clonedDiv.setAttribute('x-data', '{ checked: true }');
            updateProductInClonedDiv(clonedDiv, timeOfDay, routineId, productId, productName, productThumbnailUrl);
            if (lastChildDiv.nextSibling) {
                routineDiv.insertBefore(clonedDiv, lastChildDiv.nextSibling);
            } else {
                routineDiv.appendChild(clonedDiv);
            }
        }
    }
}

function updateProductInClonedDiv(clonedDiv, timeOfDay, routineId, newProductId, productName, productThumbnailUrl) {
    updateInputProduct(clonedDiv, newProductId, routineId, timeOfDay);
    updateLabel(clonedDiv);
    updateImage(clonedDiv, productName, productThumbnailUrl);
    removeHiddenInput(clonedDiv);
    updateAllInputs(clonedDiv);
}

function updateInputProduct(clonedDiv, newProductId, routineId, timeOfDay) {
    const inputProduct = clonedDiv.querySelector('input[type="checkbox"]');

    if (inputProduct) {
        inputProduct.value = newProductId;
        inputProduct.id = `${newProductId}_${routineId}_${timeOfDay}`;
        inputProduct.checked = true; // Use property for boolean attributes
    }
}

function updateLabel(clonedDiv) {
    const label = clonedDiv.querySelector('label');
    const inputProduct = clonedDiv.querySelector('input[type="checkbox"]');
    if (label && inputProduct) {
        label.htmlFor = inputProduct.id; // Use htmlFor for consistency
    }
}

function updateImage(clonedDiv, productName, productThumbnailUrl) {
    const img = clonedDiv.querySelector('img');
    if (img) {
        img.src = productThumbnailUrl;
        img.alt = productName;
    }
}

function removeHiddenInput(clonedDiv) {
    const hiddenInputs = clonedDiv.querySelectorAll('input[type="hidden"]');
    hiddenInputs.forEach(input => {
        if (input.name.endsWith('[id]')) {
            input.remove();
        }
    });
}

function updateAllInputs(clonedDiv) {
    const allInputs = clonedDiv.querySelectorAll('input');
    allInputs.forEach(input => {
        const nameMatch = input.name.match(/routine_log_products_attributes\]\[(\d+)\]/);
        if (nameMatch) {
            const number = parseInt(nameMatch[1], 10);
            input.name = input.name.replace(nameMatch[0], `routine_log_products_attributes][${number + 1000}]`);
        }

        if (input.id.includes('routine_log_products_attributes')) {
            const idMatch = input.id.match(/routine_log_products_attributes_(\d+)_/);
            if (idMatch) {
                const number = parseInt(idMatch[1], 10);
                input.id = input.id.replace(idMatch[0], `routine_log_products_attributes_${number + 1000}_`);
            }
        }

        if (input.name.includes('_destroy')) {
            input.value = "0";
        }
    });
}