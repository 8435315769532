import Sortable from 'sortablejs';

document.addEventListener("turbo:load", function() {
  if (document.getElementById("edit_routine")) {
    let routine = document.getElementById('edit_routine');
    let morning_routine = document.getElementById('morning_routine');
    let evening_routine = document.getElementById('evening_routine');
    let data_routine = routine.dataset.routine;

    if(morning_routine !== null) {
        createRoutineSortable(morning_routine, data_routine);
    }

    if(evening_routine !== null) {
        createRoutineSortable(evening_routine, data_routine);
    }
  }
});

function createRoutineSortable(element, data_routine) {
    let csrfToken = document.querySelector("[name='csrf-token']").content

    new Sortable(element, {
        handle: '.sort-handle',
        easing: "cubic-bezier(1, 0, 0, 1)",
        ghostClass: 'invisible',
        chosenClass: "!bg-warm-gray-200",
        animation: 150,
        delay: 150,
        delayOnTouchOnly: true,
        // Event when you move an item in the list or between lists
        onEnd: function(evt) {
            let new_order = {};
            [].forEach.call(evt.to.getElementsByClassName('sortable-item'), function(el, index) {

                let sort_ids = el.getElementsByClassName('step-id');
                let data_id = el.dataset.id;

                if(evt.item.dataset.id == data_id) {
                    new_order[data_id] = index+1;
                }

                // Update displayed text
                [].forEach.call(sort_ids, function(obj, i) {
                    obj.textContent = index + 1;
                });

            });
            let formData = new FormData();
            formData.append('routine_products', JSON.stringify(new_order));

            fetch('/routines/routine-products/'+ data_routine, {
                method: "PATCH",
                headers: {
                    "X-CSRF-Token": csrfToken
                },
                body: formData
            });
        }
    });
}