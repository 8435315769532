import {debounce} from "../application";

document.addEventListener("turbo:load", function() {

    const navbar = document.getElementById('masthead');
    let lastScrollTop = 0;
    let navHidden = false;

    function handleScroll() {
        const scrollTop = document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            hideNavbar();
        } else if (scrollTop < lastScrollTop - 5) {
            showNavbar();
        }

        lastScrollTop = scrollTop;
    }

    function hideNavbar() {
        if(!navHidden) {
            navbar.style.top = `-${navbar.offsetHeight}px`;
            navHidden = true;
        }
    }

    function showNavbar() {
        if(navHidden) {
            navbar.style.top = '0';
            navHidden = false;
        }
    }

    if (navbar) {
        if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            window.addEventListener('scroll', debounce(handleScroll, 15));
        }
    }
});