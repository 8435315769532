document.addEventListener("turbo:load", function() {
   let textArea = document.querySelectorAll("textarea#ingredients_list")[0];

   if(textArea) {
      textArea.addEventListener("input", function() {
         let resultElement = document.getElementById("ingredients_list_count");
         let ingredientCount = countIngredients(textArea.value)

         resultElement.innerText = `Potential Ingredients Detected: ${ingredientCount}`;
      })
   }
})

function countIngredients(ingredients_list) {
   const regex =  /(?:,(?![^(]*\\))(?![0-9]*[0-9])|(?:\\(and*\\))|(?:;(?![^(]*\\))(?![0-9]*[0-9])|(?:･)|(?:•)|(?:●)|(?:‚(?![^(]*\\))(?![0-9]*[0-9])/

   let split_list = ingredients_list.split(regex)
       .filter(x => x !== undefined).filter(x => x.trim() !== '');

   return split_list.length;
}