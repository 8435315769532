import {debounce} from "../application";
import {Spinner} from "spin.js";
import {opts} from "./spinner_opts";

document.addEventListener("turbo:load", function() {
    initializeUserProductSearchBars(document);
});

function initializeUserProductSearchBars(element) {
    const userProductSearchInputs = Array.from(element.getElementsByClassName('user-product-search-input'));

    userProductSearchInputs.forEach(userProductSearchInput => {
        initUserProductSearchBar(userProductSearchInput);
    });
}

function initUserProductSearchBar(textInput) {
    if(textInput) {
        textInput.addEventListener("input", debounce(function () {
            if(textInput.value.length < 2) {
                return;
            }

            let form = textInput.closest('form'); // Find the closest form element

            let resultsDiv = document.getElementById(textInput.dataset.resultsDivId);

            new Spinner(opts).spin(resultsDiv);
            resultsDiv.classList.add("opacity-50");

            form.requestSubmit();
        }, 300));
    }
}